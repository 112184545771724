










import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'DeskTech',
  components: { AsyncTechList: () => import('../async/tech-list.vue') },
  setup() {
    const show = ref<boolean>(false)
    const handleSetShow = (state: boolean) => (show.value = state)
    return {
      show,
      handleSetShow
    }
  }
})
